/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import { KOREVAL_LINK, CONTACTS_LINK } from './links';

export const FAQ_PAGE = [
  {
    title: 'What we do',
    paragraph: (
      <p>
        Our analysts research properties & activities in destinations around the
        world and publish comprehensive reviews on the website. At the same
        time, we score those properties & activities based on over 20 criteria
        so that, thanks to our proprietary algorithms we can derive a unique
        score (Personal Value or “PV”) and ranking based on your personal
        preferences, interests, and budget.
      </p>
    ),
  },
  {
    title: 'Differences Vs other websites/apps',
    paragraph: (
      <>
        <p>
          Here are some of the main differences between us and other well-known
          platforms:
        </p>
        <div className="ml-15">
          <div>- No fake and paid-for reviews</div>
          <div>
            - We take user ratings into account but do not follow their scoring
            which suffers from several issues such as extreme ratings, lack of
            objectivity, and inconsistency in scale;
          </div>
          <div>
            - Environmental & Social aspects are rated and part of the
            valuation;
          </div>
          <div>
            - We do not mislead our users by placing sponsored offers at the top
            of the rankings
          </div>
          <div>
            - Our ranking takes into account several variables and their
            respective. This is much more powerful and useful than basic filters
            & sorting functionalities
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Profile set-up',
    paragraph: (
      <p>
        Think of your profile as part of the search parameters: the more
        accurate it is, the more relevant the results will be. This is why we
        recommend that you take a couple of minutes electing the degree of
        importance of each “category” or feature. Just in case this is in your
        mind, we are not selling your data to anyone.
      </p>
    ),
  },
  {
    title: 'Reviews and ratings',
    paragraph: (
      <>
        <p>
          Koreval, a business division of Koralgo, uses Review Analysts to carry
          out an extensive search through the internet and compiles relevant
          information, including the essence of online customer reviews.
        </p>
        <p>There are two types of reviews:</p>
        <div className="ml-15">
          <div>
            - Desktop where no site visit takes place, and the analyst relies on
            information that is publicly available information and any materials
            provided by the Seller;
          </div>
          <div>
            - Onsite which includes a formal visit of the analyst to the
            property or activity and a meeting with the Seller management.
          </div>
        </div>
        <p />
        <p>
          Please use this{' '}
          <Link className="inlined-link" to={CONTACTS_LINK}>
            contact form
          </Link>{' '}
          if you are interested in Koreval services.
        </p>
      </>
    ),
  },
  {
    title: 'Itinerary and Review Packs pricing',
    paragraph: (
      <>
        <p>The current pricing before applicable taxes and Stripe fees is:</p>
        <div className="ml-15">
          <div>- US$1.00 per Itinerary exported;</div>
          <div>
            - US$0.25 per Review exported as part of an Itinerary Pack or a
            Review Pack
          </div>
        </div>
        <p />
        <p>
          Those unit prices are subject to a minimum price per pack of US$3.50
          before fees and taxes.
        </p>
      </>
    ),
  },
  {
    title: 'Payment options and fees',
    paragraph: (
      <>
        <p>
          The platform uses Stripe to process payments in a secured and
          efficient fashion using the most popular payment medium such as Visa,
          Mastercard or American Express Credit Card, Google Pay and Apple Pay
          as well as dozens of popular payment methods around the world such as
          Alipay or WeChat Pay.
        </p>
        <p>
          The distribution fee applicable for every new booking or purchase is
          charged to the supplier and is the lowest in the industry which
          ultimately results in either lower price of more features for the
          consumer.
        </p>
        <div>See below for indicative Stripe pricing</div>
        <div className="ml-15">
          <div>
            - In the USA:
            <a
              rel="noreferrer"
              target="_blank"
              href="https://stripe.com/pricing"
              className="ml-10 inlined-link"
            >
              https://stripe.com/pricing
            </a>
          </div>
          <div>
            - In the European Union:
            <a
              rel="noreferrer"
              target="_blank"
              href="https://stripe.com/en-fr/pricing"
              className="ml-10 inlined-link"
            >
              https://stripe.com/en-fr/pricing
            </a>
          </div>
          <div>
            - In Singapore:
            <a
              rel="noreferrer"
              target="_blank"
              href="https://stripe.com/en-sg/pricing"
              className="ml-10 inlined-link"
            >
              https://stripe.com/en-sg/pricing
            </a>
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Help / contact',
    paragraph: (
      <>
        Please use this contact form{' '}
        <Link className="inlined-link" to={CONTACTS_LINK}>
          here
        </Link>{' '}
        if you require assistance
      </>
    ),
  },
];

export const USER_GUIDE_PAGE = [
  {
    title: 'Find a specific review',
    paragraph: (
      <p>
        In the search box on the website’s header, key in the activity or
        property name and select the relevant match.
      </p>
    ),
    isActive: false,
  },
  {
    title: 'Rank and book hotels in a destination',
    paragraph: (
      <>
        <p>
          Select the “Stays” tab and your destination, change the inputs as
          required to personalize your search, and then click the "Find the
          Best" button.
        </p>
        <p>
          The results page shows ranked properties based on their respective
          best offers taking into account a) the quality of the property, b) the
          type of room, c) whether breakfast is included, and d) the
          cancellation terms
        </p>
        <p>
          For each property, you can use the "Booking.com" button to direct you
          towards the website of our partner Booking.com so you can complete
          your transaction. We use a revenue sharing model so there is
          absolutely no extra cost to you by coming from our platform - but if
          you close your Booking.com session and go back later then we don't
          receive anything so if you think our website is helpful, don't do that
          (or use our links every time)!
        </p>
      </>
    ),
    isActive: false,
  },
  {
    title: 'Rank and book activities in a destination',
    paragraph: (
      <>
        <p>
          Select the “Activities” tab and your destination, change the inputs as
          required to personalize your search, and then click the "Find the
          Best" button. The results page shows ranked activities based on your
          personal interests and budget.
        </p>
        <p>
          We partnered with GetYourGuide and Viator so that, for some
          activities, you can click on the “Book Activity” button and proceed
          with your booking on their platform. We use a revenue sharing model so
          there is no extra cost to you by coming from our site.
        </p>
      </>
    ),
    isActive: false,
  },
  {
    title: 'See list of activities or properties in a destination',
    paragraph: (
      <>
        <p>
          Once you have selected your destination, if you are on the
          "Activities" tab you will see the number of Activities reviews below
          the input fields, if you are on the "Stays" tab you will see the
          number of Properties reviews, and if you are on the "Itineraries" you
          will see both numbers. Click on the relevant number to open the list
          in a new tab.
        </p>
        <p>
          On that page, you can move up the geographic hierarchy using the
          breadcrumbs to display the list of Activities and Properties in those
          respective destinations.
        </p>
      </>
    ),
    isActive: false,
  },
  {
    title: 'Create a Review Pack',
    paragraph: (
      <p>
        Select your destination and the "Activities" tab then click on the
        number of Activities reviews displayed below the input fields; this will
        open a page with the list of activities. Pressing the "Review Pack"
        gives you the option to select all reviews that are of interest and
        purchase them combined into a single pdf document. You will receive an
        email with a link so that you can download that document on your
        preferred device(s).
      </p>
    ),
    isActive: false,
  },
  {
    title: 'Generate a personalized itinerary and save/export it',
    paragraph: (
      <>
        <p>
          Select the “itineraries” tab, a destination and fill the input field
          as appropriate, then press the “Find the Best” button. After a few
          seconds you will see an optimized itinerary for the selected dates.
        </p>
        <p>
          The “Select Hotels” button will enable you to rank hotel offers for
          the city (or cities) you will be staying in.
        </p>
        <p>
          The “Buy Itinerary Pack” button allows you to purchase a pdf document
          combining the itinerary with all the activity reviews contained
          within. You will receive an email with a link so that you can download
          that document on your preferred device(s)
        </p>
        <p>
          The ”Show” button starts a slideshow of the activities contained in
          the itinerary
        </p>
      </>
    ),
    isActive: false,
  },
];
