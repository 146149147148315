import React, { useState } from 'react';
import PlusIcon from '../../../../assets/img/icons/bxs-plus-circle.svg';
import MinusIcon from '../../../../assets/img/icons/bxs-minus-circle.svg';
import classes from './FaqItem.module.scss';

function FaqItem({
  title,
  paragraph,
  iconStyles,
  wrapperStyles,
  titleWrapperStyles,
  activeItem = false,
}) {
  const [isActive, setIsActive] = useState(activeItem);

  const toggleActiveFaqItem = () => setIsActive(!isActive);

  return (
    <div
      className={`${classes.faqItem} ${
        isActive ? classes.active : ''
      } ${wrapperStyles}`}
    >
      <div
        onClick={toggleActiveFaqItem}
        className={`${classes.faqTitleWrapper} ${titleWrapperStyles}`}
      >
        <div className={`${classes.faqTitle} t-700`}>{title}</div>
        {isActive ? (
          <MinusIcon
            className={`${classes.icon} ${classes.iconMinus} ${iconStyles}`}
          />
        ) : (
          <PlusIcon
            className={`${classes.icon} ${classes.iconPlus} ${iconStyles}`}
          />
        )}
      </div>
      <div className={`${classes.faqInner} t-500`}>
        <div className={classes.faqContent}>
          <div>{paragraph}</div>
        </div>
      </div>
    </div>
  );
}

FaqItem.defaultProps = {
  iconStyles: '',
  wrapperStyles: '',
  innerStyles: '',
  titleWrapperStyles: '',
};

export default FaqItem;
