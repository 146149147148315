import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import FaqItem from '../../components/KoralgoForSeller/Faq/FaqItem/FaqItem';
import { USER_GUIDE_PAGE } from '../../constans/faqData';
import styles from './index.module.scss';
import classes from '../../components/KoralgoForSeller/Faq/FaqItem/FaqItem.module.scss';
import MinusIcon from '../../assets/img/icons/bxs-minus-circle.svg';
import PlusIcon from '../../assets/img/icons/bxs-plus-circle.svg';

function Index() {
  const [isOpen, setIsOpen] = useState(USER_GUIDE_PAGE);

  const handleButtonClick = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index].isActive = !updatedIsOpen[index].isActive;
    setIsOpen(updatedIsOpen);
  };

  return (
    <Layout mainClasses="section-padded-wrapper flex-grow-1">
      <div className="info-page-container">
        <h3 className={styles.title}>
          Koralgo contains thousands of{' '}
          <span onClick={() => handleButtonClick(0)} className={styles.link}>
            property and activity reviews
          </span>{' '}
          and, thanks to our unique approach, we enable users to{' '}
          <span onClick={() => handleButtonClick(1)} className={styles.link}>
            rank hotels
          </span>{' '}
          or{' '}
          <span onClick={() => handleButtonClick(2)} className={styles.link}>
            rank activities
          </span>{' '}
          for their selected destination or just explore them in a simple{' '}
          <span onClick={() => handleButtonClick(3)} className={styles.link}>
            list format
          </span>
          . You can also{' '}
          <span onClick={() => handleButtonClick(4)} className={styles.link}>
            select activity reviews
          </span>{' '}
          that are of interest for your next trip and purchase a pdf document
          that consolidate all of them.
        </h3>
        <h3 className={styles.title}>
          The icing on the cake though, is the platform’s ability to{' '}
          <span onClick={() => handleButtonClick(5)} className={styles.link}>
            generate bespoke itineraries
          </span>{' '}
          based on specific dates and personal preferences. This itinerary and
          all related activity reviews form a personalized pdf travel guidebook
          that can be purchased for the price of a cup of coffee.
        </h3>
        {isOpen.map((faqItem, index) => (
          <div
            className={`${classes.faqItem} ${
              isOpen[index].isActive ? classes.active : ''
            } ${styles.faqItemWrapper}`}
          >
            <div
              onClick={() => handleButtonClick(index)}
              className={`${classes.faqTitleWrapper} ${styles.faqItemTitleWrapper}`}
            >
              <div className={`${classes.faqTitle} t-700`}>{faqItem.title}</div>
              {isOpen[index].isActive ? (
                <MinusIcon
                  className={`${classes.icon} ${classes.iconMinus} ${styles.icon}`}
                />
              ) : (
                <PlusIcon
                  className={`${classes.icon} ${classes.iconPlus} ${styles.icon}`}
                />
              )}
            </div>
            <div className={`${classes.faqInner} t-500`}>
              <div className={classes.faqContent}>
                <div>{faqItem.paragraph}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export default Index;
